import { CONTAINER_CLASSNAME } from '@/common/helpers/container';
import { NotificationIcon } from '@/assets/icons';
import { Link } from 'react-router-dom';
import { mergeClassName } from '@/common/utils';
import { IntrapayLogoFlat } from '@/assets/icons/intrapay-logo-flat';
import DropDown from './DropDownMenu';
import { useAppSelector } from '@/store';
import CountrySelector from './CountrySelector';
import AppLoader from '@/common/components/AppLoader';

export const Header = () => {
  const { loading } = useAppSelector((state) => state.appState);
  return (
    <>
      <header className='py-5 bg-primary bg-auth bg-no-repeat bg-[0%_54%]'>
        <div
          className={mergeClassName(
            CONTAINER_CLASSNAME,
            'flex justify-between',
          )}>
          <div className='flex  gap-x-[8.3rem]'>
            <Link to='/' className=''>
              <IntrapayLogoFlat />
            </Link>
            <CountrySelector />
          </div>
          <div className='flex items-center gap-x-[2.563rem]'>
            <button>
              <NotificationIcon scale={0.9} />
            </button>
            <DropDown />
          </div>
        </div>
      </header>
      {loading ? <AppLoader /> : null}
    </>
  );
};
