import CenterModal from '../layout/protected/centralmodal';
import { AiOutlineLoading } from 'react-icons/ai';

function AppLoader() {
  return (
    <CenterModal className=' rounded-xl bg-transparent px-12'>
      <AiOutlineLoading className='animate-spin text-[4rem] text-white' />
    </CenterModal>
  );
}

export default AppLoader;
