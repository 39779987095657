import axios, { AxiosError, AxiosProgressEvent, AxiosResponse } from 'axios';
import { APIResponseSuccessModel } from '../../types';

import {
  BASE_URL as API_URL,
  CLOUD_KEY,
  CLOUD_NAME,
  REQUEST_TIMEOUT,
} from '../config/constant';
import { fetchSubUserToken, fetchUserToken } from '../storage';
import { logoutUser } from '@/modules/auth/store/thunk';

let store: any;

export const injectStore = (_store: any) => {
  store = _store;
};

/** general headers **/
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

/** authorization header for logged in user **/
const setAuthorization = () => ({
  Authorization: `Bearer ${fetchUserToken()}`,
  'x-api-token': `${fetchUserToken()}`,
});
const setswitchUserAuthorization = () => ({
  Authorization: `Bearer ${fetchSubUserToken()}`,
  'x-api-token': `${fetchSubUserToken()}`,
});

/** axios instance **/
const instance = axios.create({
  baseURL: API_URL,
  headers,
  withCredentials: false,
});

/** timeout configuration for axios instance **/
instance.defaults.timeout = REQUEST_TIMEOUT;

let originalConfig: any;

/** axios interceptor to trigger a logout on unauthorized error response code **/
instance.interceptors.response.use(
  ({ data }: AxiosResponse): AxiosResponse<APIResponseSuccessModel> => {
    return data;
  },
  (error: AxiosError<any>): any => {
    if (error.message === 'canceled') {
      return Promise.reject({
        error: true,
        data: null,
        message: null,
      });
    }

    if (error.response?.status === 429) {
      return Promise.reject(null);
    }

    if (
      error.response?.status === 307 ||
      (error.response?.status === 401 &&
        error.response?.data?.message
          ?.toLowerCase()
          ?.includes('x_api_token')) ||
      (error.response?.status === 440 &&
        error.response?.data?.message?.toLowerCase()?.includes('expired'))
    ) {
      if (!originalConfig) {
        originalConfig = error.config;
      }
      if (originalConfig._retry === undefined) {
        originalConfig._retry = true;
        store.dispatch(logoutUser(true));
        return Promise.reject(null);
      }

      if (originalConfig._retry !== undefined) {
        return Promise.reject(null);
      }
    }

    return Promise.reject(
      error
        ? error.response
          ? error.response.data
          : {
              error: true,
              data: null,
              message: 'Request Unavilable',
            }
        : null,
    );
  },
);

/** make an axios get request **/
export const makeGetRequest = (path: string) => instance.get(path);

/** make an axios post request **/
export const makePostRequest = (path: string, payload: any) =>
  instance.post(path, payload);

/** make an axios request for a guest **/
export const makeUnauthorizedRequestWithHeadersAndPayload = async (
  method: string,
  url: string,
  data: any,
): Promise<APIResponseSuccessModel> => {
  const response: APIResponseSuccessModel = await instance.request({
    method,
    url,
    data,
    headers,
  });
  return response;
};

/** make an axios request for logged-in user **/
export const makeAuthorizedRequestWithHeadersAndPayload = async (
  method: string,
  url: string,
  data = {},
  signal?: any,
) => {
  const response: APIResponseSuccessModel = await instance.request({
    method,
    url: url,
    data,
    signal,
    headers: {
      ...headers,
      ...setAuthorization(),
    },
  });
  return response;
};
/** make an axios request for logged-in user to Switch Account **/
export const makeAuthorizedRequestToSwitchAccount = async (
  method: string,
  url: string,
  data = {},
  signal?: any,
) => {
  const response: APIResponseSuccessModel = await instance.request({
    method,
    url: url,
    data,
    signal,
    headers: {
      ...headers,
      ...setswitchUserAuthorization(),
    },
  });
  return response;
};

/** make an axios request to submit a file for a logged in user **/
export const makeRequestWithFormData = async (
  method: string,
  url: string,
  data: any,
  authorized: any,
) => {
  /** create new formdata object **/
  const formData = new FormData();

  let headers = {
    'Content-Type': 'multipart/form-data',
  };

  /** loop through and append all data to formdata object **/
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const fieldData = data[key];
      // if (key === "texts" && data[key] !== "") {
      //   data[key].forEach((txtfield, idx) => {
      //     formData.append(`texts[${idx}][format]`, txtfield.format);
      //     formData.append(`texts[${idx}][text]`, txtfield.text);
      //   });
      // }
      // if (key === "media" && data[key].length !== 0) {
      //   data[key].forEach((image) => {
      //     formData.append(key, image.src);
      //   });
      // }

      if (key !== 'texts' && key !== 'media') {
        formData.append(key, fieldData);
      }
    }
  }

  if (authorized) {
    headers = { ...headers, ...setAuthorization() };
  }

  const response: any = await instance.request({
    method,
    url: url,
    data: formData,
    headers,
  });

  return response;
};

export const makeCloudinaryPostRequest = async (
  image: File | null | undefined,
  progress: (e: AxiosProgressEvent) => void,
) => {
  /** create new formdata object **/
  const data = new FormData();

  data.append('file', image ? image : '');
  data.append('api_key', CLOUD_KEY ? CLOUD_KEY : '');
  data.append('upload_preset', 'qfelv7hm');

  const response: any = await axios.post(
    `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/auto/upload`,
    data,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: progress,
    },
  );

  return response;
};
