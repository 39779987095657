import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { Payment } from '../types';
import { getFiatExchangeRate } from './thunk';

const initialState: Payment = {
  exchangeInfo: {
    isloading: false,
    localFiat_to_usdt: 0,
    usdt_to_localFiat: 0,
  },
};

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFiatExchangeRate.fulfilled, (state, { payload, meta }) => {
        if (meta.arg.type === 'TO_FIAT') {
          state.exchangeInfo.usdt_to_localFiat = payload.value;
        }
        if (meta.arg.type === 'FROM_FIAT') {
          state.exchangeInfo.localFiat_to_usdt = payload.value;
        }
      })
      .addMatcher(isAnyOf(getFiatExchangeRate.pending), (state) => {
        state.exchangeInfo.isloading = true;
      })
      .addMatcher(
        isAnyOf(getFiatExchangeRate.fulfilled, getFiatExchangeRate.rejected),
        (state) => {
          state.exchangeInfo.isloading = initialState.exchangeInfo.isloading;
        },
      );
  },
});

export const transactionActions = transactionSlice.actions;

export default transactionSlice.reducer;
