import { mergeClassName } from '@/common/utils';
import { NavLink } from 'react-router-dom';
import { SidebarNavigationProps } from '../types';

const Sidebar__STYLES = {
  light: {
    layout: 'bg-white text-[#838383]',
    links: {
      active: 'text-primary ',
      reg: 'text-[#838383] ',
    },
  },
  dark: {
    layout: 'bg-[#3B3F47] text-[#FFFFFF]',
    links: {
      active: 'text-[#FFFFFF] ',
      reg: 'text-[#FFFFFF] ',
    },
  },
};

export const SidebarNavigation: React.FC<SidebarNavigationProps> = ({
  data,
  title,
  variant,
  className,
  target,
}) => {
  return (
    <div
      className={mergeClassName(
        'min-h-[6.563rem] py-[1rem] px-[1.6rem] rounded-[0.938rem]',
        Sidebar__STYLES[variant].layout,
        className?.container,
      )}>
      {title ? (
        <div className='mb-5'>
          <h4
            className={mergeClassName('text-sm font-bold', className?.header)}>
            {title}
          </h4>
        </div>
      ) : null}
      <div className=''>
        <ul className='text-xs font-mediu flex flex-col gap-y-4'>
          {data.map((item) => {
            if (!item.path) {
              return (
                <li key={item.id}>
                  <button
                    onClick={item.onClick}
                    className='flex items-center gap-x-4'>
                    <span>{item.icon}</span>
                    <span>{item.title}</span>
                  </button>
                </li>
              );
            }

            return (
              <li key={item.id}>
                <NavLink
                  to={item.path}
                  target={target}
                  className={({ isActive }) =>
                    mergeClassName(
                      'flex items-center gap-x-4 text-sm',
                      isActive
                        ? Sidebar__STYLES[variant].links.active
                        : Sidebar__STYLES[variant].links.reg,
                      isActive ? 'font-bold' : 'font-semibold',
                    )
                  }>
                  <span>{item.icon}</span>
                  <span>{item.title}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
