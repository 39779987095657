import { IconType } from '../types';

export const WalletIcon: React.FC<IconType> = ({ className, size = 18 }) => (
  <svg
    width={size}
    height={size}
    className={className}
    viewBox='0 0 18 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.53 10.6625C13.215 10.97 13.035 11.4125 13.08 11.885C13.1475 12.695 13.89 13.2875 14.7 13.2875H16.125V14.18C16.125 15.7325 14.8575 17 13.305 17H4.695C3.1425 17 1.875 15.7325 1.875 14.18V9.13251C1.875 7.58001 3.1425 6.3125 4.695 6.3125H13.305C14.8575 6.3125 16.125 7.58001 16.125 9.13251V10.2125H14.61C14.19 10.2125 13.8075 10.3775 13.53 10.6625Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.875 9.80845V6.38099C1.875 5.48849 2.4225 4.69346 3.255 4.37846L9.21 2.12846C10.14 1.77596 11.1375 2.46598 11.1375 3.46348V6.31347'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.9191 10.9759V12.521C16.9191 12.9335 16.5891 13.2709 16.1691 13.2859H14.6991C13.8891 13.2859 13.1466 12.6934 13.0791 11.8834C13.0341 11.4109 13.2141 10.9684 13.5291 10.6609C13.8066 10.3759 14.1891 10.2109 14.6091 10.2109H16.1691C16.5891 10.2259 16.9191 10.5634 16.9191 10.9759Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.25 9.5H10.5'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
