import usdticon from '@/assets/images/usdticon.png';
import { mergeClassName } from '@/common/utils';
import { useCountryInfo } from 'use-country-info';

type Props = {
  name: string;
  className?: string;
  showName?: boolean;
  isExchange?: boolean;
  classNames?: Record<string, string>;
};

function CountryFlag({
  name,
  className,
  showName = true,
  isExchange,
  classNames,
}: Props) {
  const { countryflags } = useCountryInfo();
  const country = countryflags.find(
    (item) => item.name?.common?.toLowerCase() === name?.toLowerCase(),
  );

  return (
    <>
      {name ? (
        <h4
          className={mergeClassName(
            'flex cursor-pointer items-center gap-x-5 text-sm font-bold leading-3',
            showName ? 'py-1 px-6' : '',
            className,
          )}>
          <img
            src={country?.flags?.png || usdticon}
            className={mergeClassName(
              'object-cover rounded-full',
              isExchange ? 'size-3.5' : 'size-7',
              classNames?.icon,
            )}
            alt={country?.flags?.alt || 'usdt'}
          />{' '}
          {showName ? name : null}
        </h4>
      ) : null}
    </>
  );
}

export default CountryFlag;
