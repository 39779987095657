import React, { InputHTMLAttributes, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { FieldError } from 'react-hook-form';
import { mergeClassName } from '@/common/utils';
import { Copy } from 'iconsax-react';
import { useClipboard } from '@/common/hooks/useClipboard';
import { BsCheckAll } from 'react-icons/bs';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  labelText?: string | React.ReactNode;
  error?: FieldError;
  hideErrorText?: boolean;
  classNames?: Record<string, string>;
  otherInfo?: string;
}

export const Input: React.FC<InputProps> = React.forwardRef<
  HTMLInputElement,
  InputProps
>(
  (
    {
      labelText,
      className,
      error,
      type,
      name,
      hideErrorText,
      classNames,
      otherInfo,
      ...rest
    },
    ref,
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const { copyToClipboard, isCopied } = useClipboard();

    const handlePasswordToggle = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      e.preventDefault();
      setIsPasswordVisible((prev) => !prev);
    };

    const renderInputLabel = () => {
      if (typeof labelText === 'string')
        return (
          <label
            // ${error && 'text-red-500'}
            className={mergeClassName(
              'text-xs font-semibold',
              error && 'text-red-500',
            )}>
            {labelText}
          </label>
        );
      return labelText;
    };

    return (
      <div className={mergeClassName('w-full', classNames?.container)}>
        <div
          className={mergeClassName(
            'flex flex-col gap-y-2 mb-1',
            classNames?.inputContainer,
          )}>
          {labelText && renderInputLabel()}
          <div className='relative'>
            <input
              {...rest}
              className={mergeClassName(
                'md:text-sm h-[3rem] outline-none bg-[#F8FAFC]  transition-all duration-200 text-xs border  rounded-xl px-4 py-2 border-opacity-40 w-full',
                error
                  ? 'border-red-500'
                  : 'border-[#CBD5E1]  focus:ring-2 focus:ring-primary focus:ring-opacity-40',
                type === 'password' ? 'pr-8' : '',
                className,
                classNames?.input,
              )}
              type={isPasswordVisible ? 'text' : type}
              name={name}
              ref={ref}
            />
            {name?.includes('password') && (
              <button
                className='absolute right-3 top-[33%]'
                onClick={handlePasswordToggle}>
                {!isPasswordVisible ? (
                  <FaEye className='text-sm text-gray-700 hover:text-gray-900' />
                ) : (
                  <FaEyeSlash className='text-sm text-gray-700 hover:text-gray-900' />
                )}
              </button>
            )}
            {name === 'secret' && (
              <button
                className='absolute right-3 top-[33%]'
                onClick={() => copyToClipboard(rest.value?.toString() || '')}>
                {isCopied ? (
                  <BsCheckAll className='text-lg text-gray-700 hover:text-gray-900' />
                ) : (
                  <Copy className='text-sm text-gray-700 hover:text-gray-900' />
                )}
              </button>
            )}
          </div>
        </div>
        {!hideErrorText ? (
          <div className={mergeClassName('h-4', classNames?.errorContainer)}>
            {error && (
              <span className='text-xs text-red-500 font-medium'>
                {error.message || 'field required'}
              </span>
            )}
          </div>
        ) : null}
        {otherInfo ? (
          <div className={mergeClassName('h-4 text-right')}>
            <span
              className={mergeClassName(
                'text-xs text-green-500 font-medium text-right',
                classNames?.otherInfo,
              )}>
              {otherInfo}
            </span>
          </div>
        ) : null}
      </div>
    );
  },
);

export default Input;
