import { OverviewIcon } from '@/assets/icons/overview-icon';
import { PaymentIcon } from '@/assets/icons/payment-icon';
import { SettingsIcon } from '@/assets/icons/settings-icon';
import { TransactionIcon } from '@/assets/icons/transaction-icon';
import { WalletIcon } from '@/assets/icons/wallet-icon';
import { routeLinks } from '../../../../routes/route-links';

export const mainNavLinks = [
  {
    id: 1,
    title: 'Overview',
    icon: <OverviewIcon />,
    path: routeLinks.protected.overview,
  },
  {
    id: 2,
    title: 'Wallets',
    icon: <WalletIcon />,
    path: routeLinks.protected.wallets.index,
  },
  {
    id: 3,
    title: 'Transactions',
    icon: <TransactionIcon />,
    path: routeLinks.protected.transactions.index,
  },
  {
    id: 4,
    title: 'Payment',
    icon: <PaymentIcon />,
    path: routeLinks.protected.payment.index,
  },
  {
    id: 5,
    title: 'Settings',
    icon: <SettingsIcon />,
    path: routeLinks.protected.settings.index,
  },
];
