import { IconType } from '../types';

export const VideoIcon: React.FC<IconType> = ({ className, scale = 1 }) => (
    <svg
        width={scale * 18}
        height={scale * 18}
        className={className}
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='9' cy='9' r='9' fill='#A9A9A9' />
        <path d='M13 9L7 5.5359L7 12.4641L13 9Z' fill='white' />
    </svg>
);
