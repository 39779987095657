import { ENDPOINTS } from '../../../common/service/config/endpoint';
import {
  makeAuthorizedRequestWithHeadersAndPayload,
  makeUnauthorizedRequestWithHeadersAndPayload,
  makeGetRequest,
} from '../../../common/service/request';
import * as tp from '../types';

const auth = {
  async registerUser(data: any) {
    const { method, url } = ENDPOINTS.auth.signup;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async loginUser(data: any): Promise<tp.APIResponseModel<tp.IloginRes>> {
    const { method, url } = ENDPOINTS.auth.signin;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async sendOtp(data: any) {
    const { method, url } = ENDPOINTS.auth.send_otp;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async csrfToken(data: any): Promise<tp.APIResponseModel<string>> {
    const { method, url } = ENDPOINTS.auth.get_csrf_token;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async resetPassword(data: any) {
    const { method, url } = ENDPOINTS.auth.reset_password;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getAllCountries() {
    const { url } = ENDPOINTS.auth.get_countries;
    return makeGetRequest(url);
  },
};

export default auth;
