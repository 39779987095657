import React from 'react';
import { IconType } from '../types';

export const NotificationIcon: React.FC<IconType> = ({
  className,
  scale = 1,
}) => {
  return (
    <svg
      width={scale * 32}
      height={scale * 32}
      className={className}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_17124_13439)'>
        <path
          d='M13.2846 25.6385C13.9511 25.4975 18.0127 25.4975 18.6792 25.6385C19.2491 25.7701 19.8653 26.0776 19.8653 26.7491C19.8321 27.3883 19.4571 27.955 18.939 28.3149C18.2671 28.8386 17.4787 29.1703 16.6544 29.2898C16.1986 29.3489 15.7507 29.3502 15.3107 29.2898C14.4852 29.1703 13.6967 28.8386 13.0262 28.3135C12.5067 27.955 12.1317 27.3883 12.0986 26.7491C12.0986 26.0776 12.7148 25.7701 13.2846 25.6385ZM16.0606 2.66797C18.8342 2.66797 21.6673 3.98399 23.3503 6.16752C24.4422 7.57352 24.9431 8.97818 24.9431 11.1617V11.7297C24.9431 13.4043 25.3857 14.3913 26.3597 15.5288C27.0978 16.3667 27.3337 17.4424 27.3337 18.6093C27.3337 19.775 26.9507 20.8815 26.1834 21.7799C25.179 22.8569 23.7624 23.5444 22.3166 23.6639C20.2216 23.8425 18.1252 23.9929 16.001 23.9929C13.8755 23.9929 11.7804 23.903 9.68533 23.6639C8.23827 23.5444 6.82168 22.8569 5.81855 21.7799C5.05129 20.8815 4.66699 19.775 4.66699 18.6093C4.66699 17.4424 4.90419 16.3667 5.64098 15.5288C6.64544 14.3913 7.05889 13.4043 7.05889 11.7297V11.1617C7.05889 8.91909 7.6181 7.45266 8.76965 6.01712C10.4817 3.92356 13.2261 2.66797 15.9414 2.66797H16.0606Z'
          fill='#637381'
        />
        <circle cx='27.001' cy='11' r='5' fill='#FF4842' />
      </g>
      <defs>
        <clipPath id='clip0_17124_13439'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
