import { createAsyncThunk } from '@reduxjs/toolkit';
import service from '../service';
import customToast from '@/common/components/CustomToast';
import * as tp from '../types';
import { getMerchantInfo } from '../../transactions/store/thunk';
import { getInfoNew } from '../../overview/store/thunk';

export const getFiatExchangeRate = createAsyncThunk(
  'payment/getFiatExchangeRate',
  async (
    data: tp.IexchangeQueryThunk,
    { rejectWithValue, fulfillWithValue },
  ) => {
    return service
      .getFiatExchangeRate(data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);
export const swapFunds = createAsyncThunk(
  'payment/swapFunds',
  async (
    data: tp.IswapFundsThunk,
    { rejectWithValue, fulfillWithValue, dispatch },
  ) => {
    return service
      .swapFunds(data)
      .then((res) => {
        dispatch(getMerchantInfo(data.user_id));
        dispatch(getInfoNew({ user_id: data.user_id }));
        customToast(res.message);
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);
