import { Route, Routes } from 'react-router-dom';
import { generateRoute, routes } from './common/routes';
import Layout from './common/components/layout/app/app-layout';

function App() {
  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          {routes.map((route) => generateRoute(route))}
        </Route>
        <Route path='*' element={<p>NOT FOUND</p>} />
      </Routes>
    </>
  );
}

export default App;
