import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import appState from './appState/slice';
import auth from '../modules/auth/store/slice';
import transactions from '../modules/protected/transactions/store/slice';
import dashboard from '../modules/protected/overview/store/slice';
import payment from '../modules/protected/payment/store/slice';
import wallet from '../modules/protected/wallet/store/slice';

// const MIGRATE_DEBUG = false;
const appPersistConfig = {
  key: 'appState',
  storage: storage,
  whitelist: ['appState', 'subAccounts'],
  version: 1,
  migrate: (state: any) => {
    return Promise.resolve(state);
  },
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['user'],
  version: 1,
  migrate: (state: any) => {
    return Promise.resolve(state);
  },
};
// const settingPersistConfig = {
//   key: 'settings',
//   storage: storage,
//   whitelist: ['api_setting', 'settlement_info'],
//   version: 1,
//   migrate: (state: any) => {
//     return Promise.resolve(state);
//   },
// };
const walletPersistConfig = {
  key: 'wallet',
  storage: storage,
  whitelist: [
    'p2p_info',
    'payment_config',
    'min_payout_amounts',
    'pending_balance',
  ],
  version: 1,
  migrate: (state: any) => {
    return Promise.resolve(state);
  },
};

const rootReducer = combineReducers({
  appState: persistReducer(appPersistConfig, appState),
  auth: persistReducer(authPersistConfig, auth),
  transactions,
  dashboard,
  payment,
  wallet: persistReducer(walletPersistConfig, wallet),
});

export default rootReducer;
