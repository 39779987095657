import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getInfoNew } from './thunk';
import { getMerchantInfo } from '../../transactions/store/thunk';
import { Idashboard } from '../types';
import { logoutUser } from '@/store/appState/thunk';

const initialState: Idashboard = {
  loading: false,
  error: null,
  currency: '',
  total_collection: 0,
  total_payout: 0,
  merchantInfo: {
    wallet_balance: 0,
    total_pending: 0,
    total_mismatched: 0,
    total_collection_count: 0,
    total_payout_count: 0,
    total_pending_count: 0,
    total_mismatched_count: 0,
  },
  dashboardInfo: {
    admin_fee_usdt_trc20: 0,
    capital_wallet: 0,
    commission_wallet: 0,
    total_processed_user: 0,
    dispute: 0,
    total_request: 0,
    insurance_amount: 0,
    total_funding_txn: 0,
    ranking: 0,
    total_withdrawal_txn: 0,
    locked_fund: 0,
    total_funded_amount: 0,
    total_withdrawal_amount: 0,
  },
  p2p_payout_stats: {
    total_failed_payout: 0,
    total_completed_payout: 0,
  },
  kyc_wallet_balance: 0,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInfoNew.fulfilled, (state, action) => {
        state.kyc_wallet_balance = action.payload.kyc_wallet_balance;
        (
          Object.keys(state.dashboardInfo) as Array<
            keyof typeof state.dashboardInfo
          >
        ).forEach((key) => {
          state.dashboardInfo[key] = action.payload[key];
        });
      })
      .addCase(getMerchantInfo.fulfilled, (state, action) => {
        (
          Object.keys(state.merchantInfo) as Array<
            keyof typeof state.merchantInfo
          >
        ).forEach((key) => {
          state.merchantInfo[key] = action.payload[key];
        });
      })
      .addCase(logoutUser.fulfilled, (state) => {
        (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
          switch (key) {
            case 'loading':
            case 'error':
            case 'dashboardInfo':
            case 'merchantInfo':
            case 'p2p_payout_stats':
              state[key] = initialState[key];
              break;
            case 'currency':
              state[key] = initialState[key];
              break;

            default:
              state[key] = initialState[key];
              break;
          }
        });
      })

      .addMatcher(
        isAnyOf(
          getInfoNew.fulfilled,
          getInfoNew.pending,
          getMerchantInfo.fulfilled,
          getMerchantInfo.pending,
        ),
        (state) => {
          if (state.error !== null) {
            state.error = null;
          }
        },
      )
      .addMatcher(
        isAnyOf(getInfoNew.rejected, getMerchantInfo.rejected),
        (state) => {
          state.error = true;
        },
      )
      .addMatcher(
        isAnyOf(getInfoNew.pending, getMerchantInfo.pending),
        (state) => {
          state.loading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          getInfoNew.rejected,
          getInfoNew.fulfilled,
          getMerchantInfo.rejected,
          getMerchantInfo.fulfilled,
        ),
        (state) => {
          state.loading = false;
        },
      );
  },
});

export default dashboardSlice.reducer;
