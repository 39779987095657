import { mergeClassName } from '@/common/utils';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import { ClipLoader } from 'react-spinners';

interface BaseProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  size?: 'sm' | 'md' | 'xl';
  variant?: 'primary' | 'danger' | 'secondary' | 'text';
}

type ButtonProps = BaseProps &
  (
    | { text: string; children?: never }
    | { children: React.ReactNode; text?: never }
  );

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      className,
      children,
      loading,
      disabled,
      size = 'md',
      variant = 'primary',
      ...rest
    },
    ref,
  ) => {
    const getBackgroundColor = (
      variant: 'primary' | 'danger' | 'secondary' | 'text',
    ) => {
      switch (variant) {
        case 'danger':
          return 'bg-danger';

        case 'secondary':
          return 'bg-secondary';
        case 'text':
          return 'bg-transparent text-primary';

        default:
          return 'bg-primary';
      }
    };

    const getSizeStyles = (size: 'sm' | 'md' | 'xl') => {
      switch (size) {
        case 'sm':
          return 'px-[0.875rem] py-[0.438rem] min-w-[5rem] text-xs';
        case 'md':
          return 'py-2.5 px-4 text-sm font-bold min-w-[5rem] rounded-xl';

        default:
          return 'w-full md:max-w-[10.938rem] p-[1.5rem] text-sm h-[3rem]';
      }
    };

    return (
      <button
        {...rest}
        ref={ref}
        className={mergeClassName(
          'text-white disabled:cursor-not-allowed disabled:bg-opacity-70 rounded-lg py-3 font-semibold inline-flex items-center justify-center',
          getSizeStyles(size),
          getBackgroundColor(variant),
          className,
        )}
        disabled={loading || disabled}>
        {loading ? <ClipLoader color='white' size={15} /> : text || children}
      </button>
    );
  },
);

export default Button;
