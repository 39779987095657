import { IconType } from '../types';

export const OverviewIcon: React.FC<IconType> = ({ className, scale = 1 }) => (
  <svg
    width={16 * scale}
    height={15 * scale}
    className={className}
    viewBox='0 0 16 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.1216 0.982531C3.2588 0.982531 0.125 4.11453 0.125 7.97913C0.125 11.8437 3.257 14.9775 7.1216 14.9775C10.9862 14.9775 14.1182 11.8455 14.1182 7.97913H7.1216V0.982531ZM15.875 7.17813C15.875 5.24673 15.092 3.49713 13.8266 2.23173L8.8784 7.17813H15.875ZM8.0072 0.0195312V7.01433L12.9536 2.06793C11.5874 0.701731 9.7964 0.0195312 8.0072 0.0195312Z'
      fill='currentColor'
    />
  </svg>
);
