import { IconType } from '../types';

export const SettingsIcon: React.FC<IconType> = ({ className, scale = 1 }) => (
  <svg
    width={scale * 18}
    height={scale * 19}
    className={className}
    viewBox='0 0 18 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9 11.75C10.2426 11.75 11.25 10.7426 11.25 9.5C11.25 8.25736 10.2426 7.25 9 7.25C7.75736 7.25 6.75 8.25736 6.75 9.5C6.75 10.7426 7.75736 11.75 9 11.75Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.5 10.1611V8.84105C1.5 8.06105 2.1375 7.41605 2.925 7.41605C4.2825 7.41605 4.8375 6.45605 4.155 5.27855C3.765 4.60355 3.9975 3.72605 4.68 3.33605L5.9775 2.59355C6.57 2.24105 7.335 2.45105 7.6875 3.04355L7.77 3.18605C8.445 4.36355 9.555 4.36355 10.2375 3.18605L10.32 3.04355C10.6725 2.45105 11.4375 2.24105 12.03 2.59355L13.3275 3.33605C14.01 3.72605 14.2425 4.60355 13.8525 5.27855C13.17 6.45605 13.725 7.41605 15.0825 7.41605C15.8625 7.41605 16.5075 8.05355 16.5075 8.84105V10.1611C16.5075 10.9411 15.87 11.5861 15.0825 11.5861C13.725 11.5861 13.17 12.5461 13.8525 13.7236C14.2425 14.4061 14.01 15.2761 13.3275 15.6661L12.03 16.4086C11.4375 16.7611 10.6725 16.5511 10.32 15.9586L10.2375 15.8161C9.5625 14.6386 8.4525 14.6386 7.77 15.8161L7.6875 15.9586C7.335 16.5511 6.57 16.7611 5.9775 16.4086L4.68 15.6661C3.9975 15.2761 3.765 14.3986 4.155 13.7236C4.8375 12.5461 4.2825 11.5861 2.925 11.5861C2.1375 11.5861 1.5 10.9411 1.5 10.1611Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
