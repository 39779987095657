import { ENDPOINTS } from '@/common/service/config/endpoint';
import {
  makeAuthorizedRequestToSwitchAccount,
  makeAuthorizedRequestWithHeadersAndPayload,
} from '@/common/service/request';
import * as tp from '@/common/types';
import { IsubAccount, ISwitchMultiAccount } from '@/common/types/appState';

const service = {
  async toggleOfflineState(id: string, data: any) {
    const { method, url: makeUrl } = ENDPOINTS.appstate.onlineOffline;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async changeLanguage(id: string, data: any) {
    const { method, url: makeUrl } = ENDPOINTS.appstate.language;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async switchMultiAccount(
    data: ISwitchMultiAccount,
  ): Promise<tp.APIResponseModel<IsubAccount>> {
    const { method, url } = ENDPOINTS.appstate.switch_multi_account;
    return makeAuthorizedRequestToSwitchAccount(method, url, data);
  },
  async getNotificationList(
    user_id: string,
  ): Promise<tp.APIResponseSuccessModel> {
    const { method, url: makeUrl } =
      ENDPOINTS.appstate.notification.get_notification_list;
    const url = makeUrl(user_id);
    return makeAuthorizedRequestToSwitchAccount(method, url);
  },
  async readNotification(data: any): Promise<tp.APIResponseSuccessModel> {
    const { method, url } = ENDPOINTS.appstate.notification.read_notification;
    return makeAuthorizedRequestToSwitchAccount(method, url, data);
  },
  async readAllNotification(data: any): Promise<tp.APIResponseSuccessModel> {
    const { method, url } =
      ENDPOINTS.appstate.notification.read_all_notification;
    return makeAuthorizedRequestToSwitchAccount(method, url, data);
  },
};

export default service;
