import { ENDPOINTS } from '@/common/service/config/endpoint';
import { makeAuthorizedRequestWithHeadersAndPayload } from '@/common/service/request';
import { APIResponseModel } from '@/common/types';
import * as tp from '../types';

const service = {
  async getFiatExchangeRate({
    user_id,
    fiat_currency,
    type,
  }: tp.IexchangeQueryThunk): Promise<APIResponseModel<tp.IexchangeQueryRes>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.swap.get_exhange_rate;
    let from_currency = fiat_currency;
    let to_currency = 'USDT';
    if (type === 'TO_FIAT') {
      from_currency = 'USDT';
      to_currency = fiat_currency;
    }
    const url = makeUrl(user_id, from_currency, to_currency);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async swapFunds(data: tp.IswapFundsThunk): Promise<APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.swap.swap_funds;
    const { user_id } = data;
    const url = makeUrl(user_id);
    const userData: any = { ...data };
    delete userData.user_id;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, userData);
  },
};

export default service;
