import React from 'react';
import { IconType } from '../types';

export const ComplianceIcon: React.FC<IconType> = ({
    className,
    scale = 1,
}) => (
    <svg
        width={scale * 21}
        height={scale * 21}
        className={className}
        viewBox='0 0 21 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='10.5' cy='10.5' r='9.5' stroke='white' strokeWidth='2' />
        <line
            x1='8'
            y1='9.50391'
            x2='13.84'
            y2='9.50391'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
        />
        <line
            x1='8'
            y1='12.3008'
            x2='13.84'
            y2='12.3008'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
        />
        <line
            x1='8'
            y1='6.70312'
            x2='11.04'
            y2='6.70312'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
        />
    </svg>
);
