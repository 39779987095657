import { iHandleChange, iHandleClick, iHandleSubmit } from '@/common/types';
import { updateField } from '@/modules/auth/store/slice';
import { getCsrfToken, getOtp } from '@/modules/auth/store/thunk';
import CustomInput from '@/common/components/forms/input';
import CustomButton from '@/common/components/forms/button';
import ResendCodeCounter from '@/common/components/ResendCodeCounter';
import useCounter from '@/common/hooks/useCounter';
import { useAppDispatch, useAppSelector } from '@/store';
import { useEffectOnce } from '@/common/hooks/useEffectOnce';
import { removeNonNumeric } from '@/common/utils';

const PageDictionary = {
  pageTitle: '',
  heading: 'Enter OTP',
  twoFAHeading: 'Enter 2FA',
  subHeading: 'Input the one time password sent to your email',
  twoFAsubHeading:
    'Input the one time password sent to your email verify your email',
  otpLabel: 'Enter Otp pin',
  submitAction: 'Submit',
  resendCode: 'Didn’t get code? Resend',
};

type Value = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  country: string;
  account_owner_email_address: string;
};

type Props = {
  submitHandler: iHandleSubmit;
  value: Value;
  loading: boolean;
};

function AddCountryEnterOtp({ value, submitHandler, loading }: Props) {
  const { count: time, counter, startCounter: startCountDown } = useCounter(90);
  const {
    auth: state,
    loading: isloading,
    user: { email },
  } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    if (state.email !== '') {
      return;
    }
    dispatch(updateField(value));
    if (state.otp) {
      const tempData = { ...state, otp: '' };
      dispatch(updateField(tempData));
    }
  });

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { value } = e.target;
    dispatch(updateField({ otp: removeNonNumeric(value) }));
  };

  const resendCode: iHandleClick = () => {
    startCountDown();
    dispatch(getCsrfToken({ email: state.email ? state.email : email }))
      .unwrap()
      .then((res) =>
        dispatch(
          getOtp({ email: state.email ? state.email : email, csrf_token: res }),
        ),
      );
  };

  useEffectOnce(() => {
    startCountDown();
  });

  const handlerDisable = counter > 0;

  return (
    <>
      <header className='flex flex-col gap-y-3.5'>
        <h2 className='text-base-2 text-2xl font-semibold leading-[3.875rem] tracking-tighter'>
          {PageDictionary.heading}
        </h2>
        <p className='text-lg font-normal leading-[1.875rem] text-gray-600'>
          {PageDictionary.subHeading}
        </p>
      </header>
      <form
        className='flex flex-1 flex-col gap-y-4 self-stretch px-8 py-4'
        onSubmit={submitHandler}>
        <CustomInput
          name='otp'
          type='text'
          labelText={PageDictionary.otpLabel}
          value={state.otp}
          onChange={changeHandler}
        />

        <div className='mt-3.5 flex flex-col items-start gap-y-5'>
          <CustomButton
            disabled={state.otp.length !== 9 || loading}
            loading={loading || isloading}
            className='h-11 w-40'>
            {PageDictionary.submitAction}
          </CustomButton>
        </div>
      </form>

      <button
        onClick={resendCode}
        disabled={handlerDisable}
        className='mt-16 flex cursor-pointer items-center gap-x-5 text-base font-medium text-gray-100 disabled:cursor-not-allowed'>
        {PageDictionary.resendCode}
        {counter ? (
          <ResendCodeCounter minutes={time.minutes} seconds={time.seconds} />
        ) : null}
      </button>
    </>
  );
}

export default AddCountryEnterOtp;
