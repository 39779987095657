import { IconType } from '../types';

export const TransactionIcon: React.FC<IconType> = ({
  className,
  scale = 1,
}) => (
  <svg
    width={18 * scale}
    height={19 * scale}
    className={className}
    viewBox='0 0 18 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.5 5.75H15M12 2L15.75 5.75L12 9.5M16.5 13.25H3M6 9.5L2.25 13.25L6 17'
      stroke='currentColor'
      strokeWidth='2'
    />
  </svg>
);
