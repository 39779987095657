import { Outlet } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import PwaPortal from './pwa-portal';

function Layout() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const query = JSON.parse(JSON.stringify(params));
  const partner_id = query?.partner_id;
  const b2bQuery = window.location.search;

  return (
    <>
      {isMobile && !partner_id && process.env.NODE_ENV === 'production' ? (
        <PwaPortal
          src={import.meta.env.VITE_APP_PWA}
          title='ios pwa'
          isMobile
        />
      ) : null}
      {partner_id ? (
        <PwaPortal
          src={`${import.meta.env.VITE_APP_V1}b2b${b2bQuery}`}
          title='Intrapay payment'
        />
      ) : null}
      {!partner_id && !isMobile ? <Outlet /> : null}
    </>
  );
}

export default Layout;
