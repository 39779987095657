import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { Ipayment } from '../types';
import { logoutUser } from '@/modules/auth/store/thunk';
import {
  getCapitalHistory,
  getCommissionHistory,
  getP2PTransactions,
} from './thunk';

const initialState: Ipayment = {
  pagination: {
    limit: 10,
    nextPage: 1,
    page: 1,
    otherQuery: {},
  },
  fiat: {
    collection: undefined,
    payout: undefined,
  },
  crypto: {
    capital: {
      data: [],
      loading: false,
    },
    commission: {
      data: [],
      loading: false,
    },
  },
};

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    // replaceCollectionData: (
    //   state,
    //   { payload }: PayloadAction<{ type: IcollectionOrderType; value: any[] }>,
    // ) => {
    //   if (typeof state.fiat !== 'undefined') {
    //     state.fiat.collection[payload.type].data = payload.value;
    //   }
    //   state.fiat.collection
    // },
    resetPagination: (state) => {
      state.pagination = initialState.pagination;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logoutUser.fulfilled, (state) => {
        const keys = Object.keys(state) as Array<keyof typeof state>;
        keys.forEach((key) => {
          if (key === 'fiat') {
            state[key] = initialState[key];
          }
          if (key === 'pagination') {
            state[key] = initialState[key];
          }
        });
      })
      .addCase(getP2PTransactions.fulfilled, (state, { payload, meta }) => {
        const hasNextPage = meta.arg.limit === payload.list.length;
        // const order_no = meta.arg.order_no;
        const date_from = meta.arg.date_from;
        const date_to = meta.arg.date_to;
        const page = meta.arg.page;
        const txn_type = meta.arg.txn_type;

        if (!state.pagination) {
          state.pagination = {
            limit: 10,
            nextPage: 1,
            page: 1,
            otherQuery: {},
          };
        }

        if (date_from || date_to) {
          state.pagination.otherQuery = { date_to, date_from };
        } else {
          state.pagination.otherQuery = {};
        }

        if (hasNextPage && state.pagination.nextPage === page) {
          state.pagination.nextPage = page + 1;
        } else {
          state.pagination.nextPage = page;
        }
        // Initialize state if it's not defined
        state = state || {};

        // Initialize state.fiat if it's not defined
        state.fiat = state.fiat || {};

        if (txn_type === 'payout') {
          const args_type = meta.arg.order_type;

          // Initialize state.fiat.payout if it's not defined
          state.fiat.payout = state.fiat.payout || {};

          // Initialize state.fiat.payout[args_type] if it's not defined
          state.fiat.payout[args_type] = state.fiat.payout[args_type] || {
            loading: false,
            data: [],
          };

          // state.payout[args_type].page = page;

          if (page === 1) {
            console.log(payload.list);
            state.fiat.payout[args_type].data = payload.list;
            return;
          }
          state.fiat.payout[args_type].data = [
            ...state.fiat.payout[args_type].data,
            ...payload.list,
          ];
        }

        if (meta.arg.txn_type === 'collection') {
          const args_type = meta.arg.order_type;
          // state.collection[args_type].page = page;

          // Initialize state.fiat.payout if it's not defined
          state.fiat.collection = state.fiat.collection || {};

          // Initialize state.fiat.payout[args_type] if it's not defined
          state.fiat.collection[args_type] = state.fiat.collection[
            args_type
          ] || { loading: false, data: [] };

          if (page === 1) {
            state.fiat.collection[args_type].data = payload.list;
            return;
          }

          state.fiat.collection[args_type].data = [
            ...state.fiat.collection[args_type].data,
            ...payload.list,
          ];
        }
      })
      .addCase(getCapitalHistory.fulfilled, (state, { payload }) => {
        const temp = payload.map((itm: any) => ({
          ...itm,
          date: itm.date.split(',').join(''),
        }));
        state.crypto.capital.data = [...temp];
      })
      .addCase(getCommissionHistory.fulfilled, (state, { payload }) => {
        state.crypto.commission.data = [...payload];
      })
      .addMatcher(isAnyOf(getP2PTransactions.pending), (state, { meta }) => {
        state.fiat = state.fiat || {};
        const txn_type = meta.arg.txn_type;
        state.fiat[txn_type] = state?.fiat?.[txn_type] || {};
        if (txn_type === 'collection') {
          const args_type = meta.arg.order_type;
          state.fiat[txn_type][args_type] = state.fiat[txn_type][args_type] || {
            loading: false,
            data: [],
          };
          state.fiat[txn_type][args_type].loading = true;
        }
        if (txn_type === 'payout') {
          const args_type = meta.arg.order_type;
          state.fiat[txn_type][args_type] = state.fiat[txn_type][args_type] || {
            loading: false,
            data: [],
          };
          state.fiat[txn_type][args_type].loading = true;
        }
      })
      .addMatcher(
        isAnyOf(getP2PTransactions.fulfilled, getP2PTransactions.rejected),
        (state, { meta }) => {
          state.fiat = state.fiat || {};
          const txn_type = meta.arg.txn_type;
          state.fiat[txn_type] = state?.fiat?.[txn_type] || {};
          if (txn_type === 'collection') {
            const args_type = meta.arg.order_type;
            state.fiat[txn_type][args_type] = state.fiat[txn_type][
              args_type
            ] || {
              loading: false,
              data: [],
            };
            state.fiat[txn_type][args_type].loading = false;
          }
          if (txn_type === 'payout') {
            const args_type = meta.arg.order_type;
            state.fiat[txn_type][args_type] = state.fiat[txn_type][
              args_type
            ] || {
              loading: false,
              data: [],
            };
            state.fiat[txn_type][args_type].loading = false;
          }
        },
      )
      .addMatcher(isAnyOf(getCapitalHistory.pending), (state) => {
        state.crypto.capital.loading = true;
      })
      .addMatcher(
        isAnyOf(getCapitalHistory.fulfilled, getCapitalHistory.rejected),
        (state) => {
          state.crypto.capital.loading = false;
        },
      )
      .addMatcher(isAnyOf(getCommissionHistory.pending), (state) => {
        state.crypto.commission.loading = true;
      })
      .addMatcher(
        isAnyOf(getCommissionHistory.fulfilled, getCommissionHistory.rejected),
        (state) => {
          state.crypto.commission.loading = false;
        },
      );
  },
});

export const transactionActions = transactionSlice.actions;

export default transactionSlice.reducer;
