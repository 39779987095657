import { BsCheckAll } from 'react-icons/bs';
import { AiOutlineCopy } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '@/store';
import { useClipboard } from '@/common/hooks/useClipboard';
import { routeLinks } from '@/common/routes';
import { mergeClassName } from '@/common/utils';

type Props = {
  className?: string;
};

function MerchantId({ className }: Props) {
  const { pathname } = useLocation();
  const { merchant_id } = useAppSelector((state) => state.wallet.p2p_info);
  const { email } = useAppSelector((state) => state.auth.user);
  const { copyToClipboard, isCopied } = useClipboard();
  const canViewId =
    pathname.includes(routeLinks.protected.wallets.index) ||
    pathname.includes(routeLinks.protected.payment.index);

  const handleCopyClick = () => {
    copyToClipboard(merchant_id);
  };
  return (
    <>
      {canViewId && merchant_id ? (
        <div className={mergeClassName('text-center', className)}>
          <p className='mx-auto w-60 overflow-hidden truncate text-ellipsis text-center text-xs'>
            {email}
          </p>
          <div className=''>
            <button
              onClick={handleCopyClick}
              className='inline-flex items-center gap-x-1'>
              <span className='text-xs'>MID: {merchant_id}</span>
              {isCopied ? (
                <BsCheckAll className='animate-pulse text-xs text-green-300' />
              ) : (
                <span className='fill-inherit text-xs'>
                  <AiOutlineCopy />
                </span>
              )}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default MerchantId;
