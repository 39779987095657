import React from 'react';
import { mergeClassName } from '@/common/utils';

type props = {
  className?: string;
  handleClose?: () => void;
  children: React.ReactNode;
};

function CenterModal({ children, handleClose, className }: props) {
  return (
    <div
      onClick={handleClose}
      className=' fixed top-0 left-0 z-20 flex h-full w-full  items-center justify-center bg-[#110E0EBD]'>
      <section
        className={mergeClassName(
          'translate top-1/2 left-1/2 flex h-auto flex-col items-center justify-center rounded bg-white p-12',
          className,
        )}
        onClick={(e) => e.stopPropagation()}>
        {children}
      </section>
    </div>
  );
}

export default CenterModal;
