import { createAsyncThunk } from '@reduxjs/toolkit';
import service from '../service';
import customToast from '@/common/components/CustomToast';
import * as tp from '../types';

export const getP2PTransactions = createAsyncThunk(
  'transactions/getP2PTransactions',
  async (
    req: tp.IpaymentCategoryThunk,
    { rejectWithValue, fulfillWithValue },
  ) => {
    return service
      .getP2PTransactions(req)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);
export const getCollectionDetail = createAsyncThunk(
  'transactions/getCollectionDetail',
  async (
    {
      user_id,
      txn_type,
      collection_id,
      bank_ref,
      currency,
      order_no,
    }: tp.IpaymentCollectionThunk,
    { rejectWithValue, fulfillWithValue },
  ) => {
    return service
      .getCollectionDetail(
        user_id,
        txn_type,
        collection_id,
        order_no,
        bank_ref,
        currency,
      )
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);
export const claimCollectionFunds = createAsyncThunk(
  'transactions/claimCollectionFunds',
  async (
    { user_id, order_no, order_type, txn_type }: tp.IclaimCollectionThunk,
    { rejectWithValue, fulfillWithValue, dispatch },
  ) => {
    return service
      .claimCollectionFunds(user_id, order_no)
      .then((res) => {
        customToast(res.message);
        dispatch(
          getP2PTransactions({
            user_id,
            txn_type,
            order_type,
            page: 1,
            limit: 10,
          }),
        );
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);
export const claimAllFunds = createAsyncThunk(
  'transactions/claimAllFunds',
  async (req: tp.Iuser, { rejectWithValue, fulfillWithValue }) => {
    return service
      .claimAllFunds(req)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);
export const getMerchantInfo = createAsyncThunk(
  'payment/getMerchantInfo',
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getMerchantInfo(id)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);
export const autoClaim = createAsyncThunk(
  'transactions/autoClaim',
  async (
    { id, status }: { id: string; status: boolean },
    { rejectWithValue, fulfillWithValue, dispatch },
  ) => {
    return service
      .autoClaim(id, status)
      .then((res) => {
        dispatch(getMerchantInfo(id));
        customToast(res?.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);

export const getCapitalHistory = createAsyncThunk(
  'transactions/getCapitalHistory',
  async (user_id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getCapitalHistory(user_id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err).payload;
      });
  },
);

export const getCommissionHistory = createAsyncThunk(
  'transactions/getCommissionHistory',
  async (user_id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getCommissionHistory(user_id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err).payload;
      });
  },
);
