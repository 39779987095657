import { IconType } from '../types';

export const DocumentationIcon: React.FC<IconType> = ({
    className,
    scale = 1,
}) => (
    <svg
        width={scale * 18}
        height={scale * 18}
        className={className}
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle
            cx='8.8418'
            cy='8.99805'
            r='7.99972'
            stroke='#A9A9A9'
            strokeWidth='1.68415'
        />
        <line
            x1='5.84208'
            y1='9.16183'
            x2='12.6543'
            y2='9.16183'
            stroke='#A9A9A9'
            strokeWidth='1.68415'
            strokeLinecap='round'
        />
        <line
            x1='5.84208'
            y1='11.5134'
            x2='12.6543'
            y2='11.5134'
            stroke='#A9A9A9'
            strokeWidth='1.68415'
            strokeLinecap='round'
        />
        <line
            x1='5.84208'
            y1='6.79855'
            x2='9.6199'
            y2='6.79855'
            stroke='#A9A9A9'
            strokeWidth='1.68415'
            strokeLinecap='round'
        />
    </svg>
);
