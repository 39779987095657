import { createAsyncThunk } from '@reduxjs/toolkit';
import customToast from '@/common/components/CustomToast';
import auth from '@/modules/auth/service';
import { IrequestOtp } from '@/modules/auth/types';
import service from '../service';
import { IfundWallet, IgetInfoThunk } from '../types';

// export const getDashboardInfo = createAsyncThunk(
//   "dashboard/getinfo",
//   async (id: string, { rejectWithValue, fulfillWithValue }) => {
//     return service
//       .getDashboardInfo(id)
//       .then((res) => {
//         // console.log(res, "res_api");
//         return res?.data?.data;
//       })
//       .catch((err) => {
//         return rejectWithValue(err);
//       });
//   }
// );
export const getInfoNew = createAsyncThunk(
  'dashboard/getinfonew',
  async (req: IgetInfoThunk, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getInfoNew(req.user_id)
      .then((res) => {
        // console.log(res.data, "get info res");
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);
export const getIncomingSettlements = createAsyncThunk(
  'dashboard/incomingSettlements',
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getIncomingSettlements(id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  },
);
export const getDisputeFunds = createAsyncThunk(
  'dashboard/disputeFunds',
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getDisputeFunds(id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  },
);
export const fundUserWallet = createAsyncThunk(
  'dashboard/fundWallet',
  async (data: IfundWallet, { rejectWithValue, fulfillWithValue }) => {
    return service
      .fundWallet(data)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  },
);
export const withdrawUserWallet = createAsyncThunk(
  'dashboard/withdrawWallet',
  async (req: any, { rejectWithValue, dispatch, fulfillWithValue }) => {
    // const { navigate, bg } = req;
    return service
      .withdrawWallet(req)
      .then((res) => {
        dispatch(getInfoNew({ user_id: req.user_id }));
        customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        // navigate(bg, { replace: true, state: null });
        return rejectWithValue(err);
      });
  },
);
export const getConfirmationCode = createAsyncThunk(
  'dashboard/getConfirmationCode',
  async (req: IrequestOtp, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .sendOtp(req)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res);
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  },
);
