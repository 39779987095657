import { IntrapayLogo } from '@/assets/icons/intrapay-logo';
import { routeLinks } from '@/common/routes';
import { fetchUserToken } from '@/common/service/storage';
import { Navigate, Outlet } from 'react-router-dom';

export const AuthLayout = () => {
  const userToken = fetchUserToken();

  if (userToken) {
    return <Navigate replace to={routeLinks.protected.overview} />;
  }
  return (
    <div className='h-screen flex overflow-hidden'>
      <div className='bg-primary bg-auth bg-no-repeat bg-contain bg-bottom flex justify-center items-center flex-[0.5] relative flex-shrink-0 overflow-hidden'>
        <IntrapayLogo />
        <div className='absolute bottom-24 '>
          <p className='text-white text-4xl'>Payment beyond border...</p>
        </div>
      </div>
      <div className='flex-[0.5] pt-20 px-12 pb-12 overflow-y-auto'>
        <div className='md:w-[80%] mx-auto'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
