import { useNavigate } from 'react-router-dom';
import { mergeClassName } from '@/common/utils';
import { useAppDispatch, useAppSelector } from '@/store';
import CountryFlag from '@/common/components/CountryFlags';
import { CgChevronDown } from 'react-icons/cg';
import {
  DropDownItem,
  DropdownMenu,
  DropDownMenuContent,
  DropdownMenuTrigger,
} from '@/common/components/CustomDropDown';
import { fetchSubUserToken, storeUserToken } from '@/common/service/storage';
import { getMerchantInfo } from '@/modules/protected/transactions/store/thunk';
import { getInfoNew } from '@/modules/protected/overview/store/thunk';
import { routeLinks } from '@/common/routes';
import { switchMultiAccount } from '@/store/appState/thunk';
import { useState } from 'react';
import AddCountryModal from './AddCountry';

const CountrySelector = () => {
  const { subAccounts, appState } = useAppSelector((state) => state.appState);
  const selectedCountry = appState.country_data.name;

  const { email, root_user_id } = useAppSelector((state) => state.auth.user);
  const { name } = appState.country_data;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const otherSubAccounts = subAccounts.filter(
    (account) => account.country !== name,
  );
  const selectedSubAccount = subAccounts.find(
    (account) => account.country === name,
  );
  const primaryAccount = subAccounts.find((account) => account.is_main);

  const accountsInVew = selectedSubAccount
    ? [selectedSubAccount, otherSubAccounts[0]]
    : [];
  const accountsNotInView = otherSubAccounts.slice(1);

  const addAccountHandler = () => {
    setIsModalOpen(true);
  };

  const closeModalHandler = () => {
    setIsModalOpen(false);
  };

  const changeAccountHandler = (
    sub_account_email: string,
    is_main: boolean,
  ) => {
    if (email === sub_account_email) return;

    // navigate(routes.protected.appLoading, {
    //   state: { background: location },
    //   replace: true,
    // });
    const newToken = fetchSubUserToken();

    if (is_main) {
      if (!newToken) return;
      storeUserToken(newToken);
      if (
        location.pathname.includes(routeLinks.protected.payment.index) === false
      ) {
        dispatch(getMerchantInfo(root_user_id));
      }
      dispatch(getInfoNew({ user_id: root_user_id, isPrimaryAccount: true }))
        .unwrap()
        .finally(() =>
          navigate(routeLinks.protected.overview, {
            replace: true,
          }),
        );
    } else {
      dispatch(switchMultiAccount({ sub_account_email, user_id: root_user_id }))
        .unwrap()
        .then((res) => {
          dispatch(getInfoNew({ user_id: res.user_id }));
          if (
            location.pathname.includes(routeLinks.protected.payment.index) ===
            false
          ) {
            dispatch(getMerchantInfo(res.user_id));
          }
        })
        .finally(() =>
          navigate(routeLinks.protected.overview, {
            replace: true,
          }),
        );
    }
  };

  return (
    <div className='flex  gap-x-[1.3rem] items-center'>
      {accountsInVew.map((item) => (
        <button
          key={item?.country}
          onClick={() => changeAccountHandler(item?.email, item?.is_main)}>
          <span className=''>
            <CountryFlag
              name={item?.country}
              className={mergeClassName(
                'border-[0.188rem] rounded-full object-cover',
                item?.country === selectedCountry
                  ? ' border-[#11C87BC2] '
                  : 'border-transparent',
              )}
              classNames={{
                icon: 'size-6',
              }}
              showName={false}
            />
          </span>
        </button>
      ))}

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button className='bg-[#0A59F3] text-white rounded-full w-[1.706rem] h-[1.706rem] flex justify-center items-center active:scale-95 transition duration-100'>
            <CgChevronDown />
          </button>
        </DropdownMenuTrigger>
        <DropDownMenuContent className='z-10 mt-2 grid w-44  rounded-lg border border-gray-250 bg-white font-bold text-blue_gray-10 shadow-team_header'>
          <DropDownItem className=''>
            <button
              onClick={addAccountHandler}
              className='w-full pt-5 pb-7 text-center text-sm font-normal leading-3 text-blue-20'>
              <span>+ Add Account</span>
            </button>
          </DropDownItem>
          {accountsNotInView.map(({ country, email, is_main }) => (
            <DropDownItem
              onClick={() => changeAccountHandler(email, is_main)}
              className='group  w-full'
              key={email}>
              <CountryFlag name={country} className='pb-7 group-last:pb-6' />
            </DropDownItem>
          ))}
        </DropDownMenuContent>
      </DropdownMenu>
      {isModalOpen ? (
        <AddCountryModal
          closeModalHandler={closeModalHandler}
          prefillData={primaryAccount?.email}
        />
      ) : null}
    </div>
  );
};

export default CountrySelector;
