import { toast } from 'react-toastify';

const customToast = (res: any[] | string, iserror?: boolean) => {
  const code = iserror === undefined ? 'success' : 'error';
  const data = typeof res === 'object' ? res[0] : res;
  return toast(data, {
    position: 'top-right',
    draggable: false,
    bodyClassName: 'toast__body',
    className: 'toast',
    type: code,
    autoClose: 3000,
  });
};

export default customToast;
