import { ENDPOINTS } from '@/common/service/config/endpoint';
import { makeAuthorizedRequestWithHeadersAndPayload } from '@/common/service/request';
import * as tp from '../types';
import { APIResponseModel } from '@/common/types';
import { queryHandler } from '@/common/utils';

const service = {
  async createWallet(
    data: tp.IcreateWalletThunk,
  ): Promise<APIResponseModel<tp.IpaymentWallet>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.create_wallet;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async uploadKyc(data: any): Promise<APIResponseModel<null>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.upload_kyc;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async registerMerchant(
    data: tp.IcreateMerchantThunk,
  ): Promise<APIResponseModel<null>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.register_merchant;
    const url = makeUrl(data.user_id);

    const indata = {
      partner_merchant_id: data.partner_merchant_id,
      merchant_callback_url: data.merchant_callback_url,
      partner_id: data.partner_id,
    };
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, indata);
  },
  async payoutMerchant(data: any): Promise<APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.payout_merchant;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async generateKey(
    data: tp.IgenerateSecretKeyThunk,
  ): Promise<APIResponseModel<tp.IgenerateSecretKeyRes>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.generate_key;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async toggleP2P(data: tp.ItoggleP2PThunk): Promise<APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.toggleP2P;
    const { partner_code, status, user_id } = data;
    const url = makeUrl(user_id);
    const inData = { partner_code, status };

    return makeAuthorizedRequestWithHeadersAndPayload(method, url, inData);
  },
  async getBankInfoFunding(
    id: string,
  ): Promise<APIResponseModel<tp.IpaymentWallet>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.get_bank_info_funding;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },

  async getPartners(id: string): Promise<APIResponseModel<tp.IpartnerInfo[]>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.get_P2P_partners;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },

  async getAllBanks({
    currency,
    user_id,
  }: tp.IallBanksThunk): Promise<APIResponseModel<any>> {
    const { method, url: makeUrl } =
      ENDPOINTS.payment.get_banks_by_country_currency;
    const url = makeUrl(user_id, currency);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },

  async resolveAccountByCurrency(
    data: tp.IresolveAccountByCurrencyThunk,
  ): Promise<APIResponseModel<any>> {
    const { method, url: makeUrl } =
      ENDPOINTS.payment.resolve_payment_withdrawal;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async paymentWithdrawal(
    data: tp.IwithDrawalPaymentThunk,
  ): Promise<APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.payment_withdrawal;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async walletHistory(
    data: tp.WalletHistoryThunk,
  ): Promise<APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.wallet.wallet_history;
    const query = queryHandler(data);
    const url = makeUrl + query;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getAddressBook(): Promise<APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.wallet.wallet_history;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async createKycLink(id: string): Promise<APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.create_kyc_link;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
