import rotateScreen from '../../../../assets/images/rotate-screen.gif';

type Props = {
  isMobile?: boolean;
  src: string;
  title: string;
};

function PwaPortal({ isMobile, ...props }: Props) {
  const portrait = window.matchMedia('(orientation: portrait)').matches;

  if (portrait === false && isMobile) {
    return (
      <div className='flex h-screen w-full items-center justify-center bg-blue_gray-10'>
        <img
          src={rotateScreen}
          className='h-20 w-20 -rotate-90'
          alt='rotate screen'
        />
      </div>
    );
  }

  return (
    <iframe
      {...props}
      // src='https://v2-intrapay-pwa.vercel.app/#/'
      className='relative h-screen w-full'
      // title='ios pwa'
    />
  );
}

export default PwaPortal;
