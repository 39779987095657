import React from 'react';
import { Route } from 'react-router-dom';
import { RouteType } from './types';
import { AuthLayout } from '@/modules/auth/components/layout';
import { ProtectedLayout } from '../components/layout/protected';
import AppLoader from '../components/AppLoader';

export const generateRoute = ({ path, Component, access }: RouteType) => {
  const layout =
    access === 'public' ? (
      <AuthLayout />
    ) : (
      <ProtectedLayout isFullScreen={access === 'loggedin-fullscreen'} />
    );

  return (
    <Route key={`${path}`} element={layout}>
      <Route
        path={path}
        element={
          <React.Suspense fallback={<AppLoader />}>
            <Component />
          </React.Suspense>
        }
      />
    </Route>
  );
};
