import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  getNotificationList,
  languageChange,
  readNotification,
  switchMultiAccount,
  toggleServiceStatus,
} from './thunk';
import { Iappstate } from '@/common/types/appState';
import { registerUser, loginUser } from '@/modules/auth/store/thunk';
import { getInfoNew } from '@/modules/protected/overview/store/thunk';

const initialState: Iappstate = {
  loading: false,
  error: null,
  appState: {
    language: 'English - EN',
    is_offline: false,
    country_data: {
      alpha2: '',
      alpha3: '',
      countryCallingCodes: [],
      currencies: [],
      ioc: '',
      languages: [],
      name: '',
    },
  },
  notification: {
    loading: false,
    data: [],
  },
  subAccounts: [],
};

const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    // toggleOfflineStatus: (state) => {
    //   state.appState.is_offline = !state.appState.is_offline;
    // },

    updateLanguage: (state, action) => {
      state.appState.language = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(languageChange.fulfilled, (state, action) => {
        state.appState.language = action.payload.language;
      })
      .addCase(getNotificationList.fulfilled, (state, action) => {
        state.notification.data = action.payload;
      })
      .addCase(readNotification.fulfilled, (state, action) => {
        state.notification.data = state.notification.data.map((itm: any) =>
          itm.notification_id === action.payload
            ? { ...itm, is_read: true }
            : itm,
        );
      })
      .addCase(getInfoNew.fulfilled, (state, { payload }) => {
        state.appState.is_offline =
          payload.online_status === 'ONLINE' ? true : false;
        state.appState.country_data = payload.country_data;
      })
      .addCase(toggleServiceStatus.fulfilled, (state) => {
        state.appState.is_offline = !state.appState.is_offline;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        if (payload?.account_owner_email_address) {
          state.subAccounts.push({
            country: payload.country,
            email: payload.email,
            is_main: false,
          });
        }
      })
      .addMatcher(isAnyOf(getNotificationList.pending), (state) => {
        state.notification.loading = true;
      })
      .addMatcher(
        isAnyOf(getNotificationList.fulfilled, getNotificationList.rejected),
        (state) => {
          state.notification.loading = false;
        },
      )
      .addMatcher(
        isAnyOf(switchMultiAccount.fulfilled, loginUser.fulfilled),
        (state, { payload, type }) => {
          state.appState.language = payload.language;
          state.appState.country_data = payload.country_data;
          if (type.includes('auth/loginUser')) {
            const temp = payload.intrapay_sub_accounts.map((itm: any) => ({
              ...itm,
              is_main: false,
            }));
            state.subAccounts = [
              {
                email: payload.email,
                country: payload.country_data.name,
                is_main: true,
              },
              ...temp,
            ];
          }
        },
      )
      .addMatcher(
        isAnyOf(toggleServiceStatus.pending, switchMultiAccount.pending),
        (state) => {
          state.loading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          toggleServiceStatus.fulfilled,
          toggleServiceStatus.rejected,
          switchMultiAccount.fulfilled,
          switchMultiAccount.rejected,
        ),
        (state) => {
          state.loading = false;
        },
      )
      .addMatcher(isAnyOf(getInfoNew.pending), (state, { meta }) => {
        if (meta?.arg?.isPrimaryAccount) {
          state.loading = true;
        }
      })
      .addMatcher(
        isAnyOf(getInfoNew.fulfilled, getInfoNew.rejected),
        (state, { meta }) => {
          if (meta?.arg?.isPrimaryAccount) {
            state.loading = false;
          }
        },
      )
      .addMatcher(
        isAnyOf(toggleServiceStatus.fulfilled, toggleServiceStatus.pending),
        (state) => {
          state.error = false;
        },
      )
      .addMatcher(isAnyOf(toggleServiceStatus.rejected), (state) => {
        state.error = true;
      });
  },
});

export const {
  // toggleOfflineStatus,
  updateLanguage,
} = appStateSlice.actions;

export default appStateSlice.reducer;
