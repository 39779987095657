import { loadAuthModules, loadModules } from './helper';
import { routeLinks } from './route-links';
import { RouteType } from './types';

export const routes: RouteType[] = [
  {
    path: routeLinks.auth.login,
    Component: loadAuthModules('login'),
    access: 'public',
  },
  {
    path: routeLinks.auth.external_payment_link,
    Component: loadAuthModules('login'),
    access: 'public',
  },
  {
    path: routeLinks.auth.register,
    Component: loadAuthModules('register'),
    access: 'public',
  },
  {
    path: routeLinks.auth.confirm_otp,
    Component: loadAuthModules('confirm-otp'),
    access: 'public',
  },
  {
    path: routeLinks.auth.forgot_password,
    Component: loadAuthModules('forgot-password'),
    access: 'public',
  },
  {
    path: routeLinks.auth.reset_password,
    Component: loadAuthModules('reset-password'),
    access: 'public',
  },
  {
    path: routeLinks.auth.reset_password_successful,
    Component: loadAuthModules('reset-password-success'),
    access: 'public',
  },
  {
    path: routeLinks.auth.two_fa,
    Component: loadAuthModules('two-fa'),
    access: 'public',
  },
  {
    path: routeLinks.auth.enter_otp,
    Component: loadAuthModules('enter-otp'),
    access: 'public',
  },
  {
    path: routeLinks.protected.compliance.index,
    Component: loadModules('protected', 'compliance'),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.compliance.kyc,
    Component: loadModules('protected', ['compliance', 'kyc']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.compliance.wallet,
    Component: loadModules('protected', ['compliance', 'CreateWallet']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.overview,
    Component: loadModules('protected', 'overview'),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.wallets.index,
    Component: loadModules('protected', 'wallet'),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.wallets.fiat.index,
    Component: loadModules('protected', ['wallet', 'fiat']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.wallets.fiat.debit,
    Component: loadModules('protected', ['wallet', 'fiat', 'debit']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.wallets.fiat.credit,
    Component: loadModules('protected', ['wallet', 'fiat', 'credit']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.wallets.fiat.api_keys,
    Component: loadModules('protected', ['wallet', 'fiat', 'api_keys']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.wallets.crypto.index,
    Component: loadModules('protected', ['wallet', 'crypto']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.wallets.crypto.withdrawal,
    Component: loadModules('protected', ['wallet', 'crypto', 'withdrawal']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.wallets.crypto.deposit,
    Component: loadModules('protected', ['wallet', 'crypto', 'deposit']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.wallets.crypto.address_book,
    Component: loadModules('protected', ['wallet', 'crypto', 'address_book']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.wallets.kyc.index,
    Component: loadModules('protected', ['wallet', 'kyc']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.wallets.kyc.debit,
    Component: loadModules('protected', ['wallet', 'kyc', 'debit']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.wallets.kyc.deposit,
    Component: loadModules('protected', ['wallet', 'kyc', 'credit']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.transactions.index,
    Component: loadModules('protected', 'transactions'),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.transactions.fiat.index,
    Component: loadModules('protected', ['transactions', 'fiat']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.transactions.fiat.collections,
    Component: loadModules('protected', [
      'transactions',
      'fiat',
      'collections',
    ]),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.transactions.fiat.payout,
    Component: loadModules('protected', ['transactions', 'fiat', 'payout']),
    access: 'loggedin-sideNav',
  },

  {
    path: routeLinks.protected.transactions.crypto.index,
    Component: loadModules('protected', ['transactions', 'crypto']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.transactions.crypto.capital,
    Component: loadModules('protected', ['transactions', 'crypto', 'capital']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.transactions.crypto.commission,
    Component: loadModules('protected', [
      'transactions',
      'crypto',
      'commission',
    ]),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.payment.index,
    Component: loadModules('protected', 'payment'),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.payment.swap.records,
    Component: loadModules('protected', ['payment', 'swap', 'records']),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.payment.swap.index,
    Component: loadModules('protected', ['payment', 'swap']),
    access: 'loggedin-fullscreen',
  },
  {
    path: routeLinks.protected.payment.send,
    Component: loadModules('protected', ['payment', 'send']),
    access: 'loggedin-fullscreen',
  },
  {
    path: routeLinks.protected.payment.links,
    Component: loadModules('protected', ['payment', 'links']),
    access: 'loggedin-fullscreen',
  },
  {
    path: routeLinks.protected.payment.address_book.list,
    Component: loadModules('protected', ['payment', 'addressbook']),
    access: 'loggedin-fullscreen',
  },
  {
    path: routeLinks.protected.payment.address_book.create,
    Component: loadModules('protected', [
      'payment',
      'addressbook',
      'createAddressBook',
    ]),
    access: 'loggedin-fullscreen',
  },
  {
    path: routeLinks.protected.settings.index,
    Component: loadModules('protected', 'settings'),
    access: 'loggedin-sideNav',
  },
  {
    path: routeLinks.protected.settings.reset_password,
    Component: loadModules('protected', ['settings', 'resetPassword']),
    access: 'loggedin-sideNav',
  },
  // {
  //   path: routeLinks.protected.settings.language_settings,
  //   Component: loadModules('protected', ['settings', 'languageSettings']),
  //   access: 'loggedin-sideNav',
  // },
];
