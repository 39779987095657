import { ENDPOINTS } from '@/common/service/config/endpoint';
import { makeAuthorizedRequestWithHeadersAndPayload } from '@/common/service/request';
import { APIResponseModel } from '@/common/types';
import * as tp from '../types';

const service = {
  async getMerchantInfo(
    id: string,
  ): Promise<APIResponseModel<tp.ImerchantInfo>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.get_P2P_merchant_info;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getP2PTransactions(
    req: tp.IpaymentCategoryThunk,
  ): Promise<APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.get_P2P_transactions;

    let params = '';

    const keys = Object.keys(req) as Array<keyof typeof req>;
    keys.forEach((key) => {
      if (req[key]) {
        if (params.length === 0) {
          params += '?';
        } else {
          params += '&';
        }

        params += `${key}=${req[key]}`;
      }
    });

    const url = makeUrl(params);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getCollectionDetail(
    id: string,
    txn_type: tp.IpaymentCategory,
    collection_id: string,
    order_no: string,
    bank_ref: string,
    currency: string,
  ): Promise<APIResponseModel<any>> {
    const { method, url: makeUrl } =
      ENDPOINTS.payment.get_P2P_collection_detail;
    const url = makeUrl(
      id,
      txn_type,
      collection_id,
      order_no,
      bank_ref,
      currency,
    );
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async claimCollectionFunds(
    id: string,
    order_no: string,
  ): Promise<APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.claim_collection;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, {
      order_no,
    });
  },
  async claimAllFunds(params: tp.Iuser): Promise<APIResponseModel<any>> {
    const { user_id } = params;
    const { method, url: makeUrl } = ENDPOINTS.payment.claim_all_funds;
    const url = makeUrl(user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async autoClaim(id: string, status: boolean): Promise<APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.auto_claim;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, { status });
  },
  async getCapitalHistory(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.crypto_wallet.capital;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getCommissionHistory(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.crypto_wallet.commission;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
