import * as clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import redux from '../../store';
import dayjs from 'dayjs';
import numeral from 'numeral';

export const mergeClassName = (...className: clsx.ClassValue[]) => {
  return twMerge(clsx.clsx(...className));
};

export const validationRules = {
  string: /(.|\s)*\S(.|\s)*/,
  email:
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
  phone: /^\+?[1-9][0-9]{7,14}$/,
  nigerian_phone: /^(\+234|0)[789]\d{9}$/,
  number: /^\d+$/,
  website:
    /^http:\/\/localhost|https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/, //eslint-disable-line
  ip: /\b(?:\d{1,3}\.){3}\d{1,3}\b/,
};

const PASSWORD_VALIDATION_RULES = [
  {
    id: 1,
    title: '1 Capital Letter',
    passed: false,
    regex: '^(?=.*?[A-Z])',
  },
  { id: 2, title: '1 Number', passed: false, regex: '(?=.*?[0-9])' },
  {
    id: 3,
    title: '1 Special Character from !@#$%^&_',
    passed: false,
    regex: '(?=.*[!@#$%^&_])',
  },
  { id: 4, title: '8 Characters', passed: false, regex: '.{8,}' },
];

export const checkPasswordStrength = (
  password: string,
): { count: number; total: number } => {
  if (!password) return { count: 0, total: PASSWORD_VALIDATION_RULES.length };

  let count = 0;

  PASSWORD_VALIDATION_RULES.forEach((rule) => {
    if (password.match(rule.regex)) count += 1;
  });

  return { count, total: PASSWORD_VALIDATION_RULES.length };
};

export const addCommas = (num: string) =>
  num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const removeNonNumeric = (num: string) =>
  num?.toString()?.replace(/[^0-9]/g, '');
export const removeCommas = (num: string) =>
  num?.toString()?.replace(/[, ]+/g, '')?.trim();

export const toCurrency = (amount: number) => {
  const country_data = redux.store.getState().appState.appState.country_data;
  // const country_data: any = redux.store.getState();

  const alpha2 = country_data?.alpha2;
  const language = country_data?.languages?.[0];
  const currency = country_data?.currencies?.[0];
  const code = `${language}-${alpha2}`;

  const data =
    alpha2 &&
    amount?.toLocaleString(code, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  return data;
};

export const FormatNumber = (
  value: number | string,
  curr?: boolean,
): string => {
  if (typeof value === 'string') value = Number(value);

  const currency = curr ? '$' : '';
  return currency + numeral(value).format('0,0.00');
};

export const toDollar = (amount: number) => {
  const currency = amount.toLocaleString(`eng-US`, {
    style: 'currency',
    currency: `USD`,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return currency;
};

export const getCurrentChildPath = (
  paths: { name: string; value: string }[] = [],
  current: string,
) => {
  const foundPath = paths.find((path) => path.value === current)?.name;

  return foundPath || '';
};
function convertObjectToCSV(data: object[]): string {
  const headers = Object.keys(data[0]);
  const rows = data.map((item: any) =>
    headers.map((header: string) => item[header]),
  );

  const csvContent = [
    headers.join(','),
    ...rows.map((row: any[]) => row.join(',')),
  ].join('\n');

  return csvContent;
}

export async function downloadObjectAsCSV(
  data: object[],
  filename: string,
): Promise<void> {
  const csvContent = convertObjectToCSV(data);
  const csvData = new Blob([csvContent], { type: 'text/csv' });
  const csvURL = URL.createObjectURL(csvData);

  const linkElement = document.createElement('a');
  linkElement.href = csvURL;
  linkElement.download = filename;

  // Simulating an asynchronous operation with a Promise
  await new Promise<void>((resolve) => {
    linkElement.addEventListener(
      'click',
      () => {
        resolve();
      },
      { once: true },
    );

    linkElement.click();
  });

  // Clean up the URL object after the download
  URL.revokeObjectURL(csvURL);
}

export const truncatedText = (text: string, maxlength: number) => {
  if (text?.length > maxlength) {
    return `${text?.substring(0, 3)}...${text?.substring(text?.length - 4)}`;
  }

  return text;
};

export const greetUser = () => {
  const hour = dayjs().hour();

  let message = '';
  switch (true) {
    case hour < 12:
      message = 'Good Morning';
      break;
    case hour > 12 && hour < 16:
      message = 'Good Afternoon';
      break;

    default:
      message = 'Good Evening';
      break;
  }

  return message;
};

export const queryHandler = (data: Record<string, string | number>): string => {
  let query = '?';

  const keys = Object.keys(data) as Array<keyof typeof data>;

  keys.forEach((key) => {
    if (data[key] !== '' || data[key] !== undefined || data[key] !== null) {
      query += `${key}=${data[key]}&`;
    }
  });

  return query.substring(0, query.length - 1);
};
