import {
  DropDownItem,
  DropdownMenu,
  DropDownMenuContent,
  DropdownMenuTrigger,
} from '../../../../CustomDropDown';
// import setting_image from '../../../../../static/images/icons/setting.svg';
// import logout_image from '../../../../../static/images/icons/log-out.svg';
import { useNavigate } from 'react-router-dom';
import { routeLinks } from '@/common/routes';
import { logoutUser } from '@/modules/auth/store/thunk';
import { useAppDispatch } from '@/store';
import { UserIcon } from '@/assets/icons/user-icon';
import { IoIosArrowDown } from 'react-icons/io';
import { CgProfile } from 'react-icons/cg';
import { IoLogOutOutline, IoSettingsOutline } from 'react-icons/io5';

function DropDown() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goTosetting = () => {
    navigate(routeLinks.protected.settings.index);
  };
  const goToProfile = () => {
    navigate(routeLinks.protected.settings.index);
  };

  const logoutHandler = () => {
    dispatch(logoutUser());
    navigate(routeLinks.auth.login, { replace: true });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className='flex cursor-pointer items-center gap-x-2 outline-none'>
          <span>
            <UserIcon scale={0.9} />
          </span>
          <span>
            <IoIosArrowDown className='text-lg' />
          </span>
        </button>
      </DropdownMenuTrigger>
      <DropDownMenuContent className='z-10 mt-4 flex w-48 flex-col gap-y-2.5 rounded-lg bg-white px-4 pt-3.5 pb-7 font-bold text-black shadow-2xl'>
        <DropDownItem>
          <button
            onClick={goToProfile}
            className='flex w-full items-center gap-x-2 text-sm text-black'>
            <CgProfile />
            <span>Profile</span>
          </button>
        </DropDownItem>
        <DropDownItem>
          <button
            onClick={goTosetting}
            className='flex w-full items-center gap-x-2 text-sm text-black'>
            <IoSettingsOutline />
            <span>Setting</span>
          </button>
        </DropDownItem>
        <DropDownItem>
          <button
            onClick={logoutHandler}
            className='flex w-full items-center gap-x-2 text-sm text-red-300'>
            <IoLogOutOutline />
            <span>Logout</span>
          </button>
        </DropDownItem>
      </DropDownMenuContent>
    </DropdownMenu>
  );
}

export default DropDown;
