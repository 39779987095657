import support1 from '@/assets/images/support1.jpeg';
import support2 from '@/assets/images/support2.jpeg';
import { mergeClassName } from '@/common/utils';

function Support() {
  const supports = [support1, support2];
  return (
    <div
      style={{
        width: supports.length * 16,
      }}
      className='relative h-6'>
      {supports.map((item, index) => (
        <div
          key={item}
          className={mergeClassName(
            'size-6 absolute top-0',
            index % 2 ? 'left-4' : 'left-0',
          )}>
          <img
            src={item}
            alt='support'
            className='size-6 rounded-full border border-white top-0'
          />
        </div>
      ))}
    </div>
  );
}

export default Support;
