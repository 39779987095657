import { ENDPOINTS } from '@/common/service/config/endpoint';
import { makeAuthorizedRequestWithHeadersAndPayload } from '@/common/service/request';
import { APIResponseModel } from '@/common/types';
import * as tp from '../types';

const service = {
  async getDashboardInfo(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.dashboard.get_info;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getInfoNew(id: string): Promise<APIResponseModel<tp.IgetInfo>> {
    const { method, url: makeUrl } = ENDPOINTS.dashboard.get_info_new;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getIncomingSettlements(id: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.dashboard.get_incoming_settlements;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getDisputeFunds(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.dashboard.get_dispute_funds;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async fundWallet(data: tp.IfundWallet) {
    const { method, url } = ENDPOINTS.transaction.fund_wallet;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async withdrawWallet(data: tp.IwithdrawWallet) {
    const { method, url: makeUrl } = ENDPOINTS.transaction.withdraw_wallet;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  // async fetchTransaction(data: IfetchTransaction) {
  //   const { method, url } = ENDPOINTS.exchange.fetch_transaction;
  //   return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  // },
  // async transactionUpdate() {
  //   const { method, url } = ENDPOINTS.exchange.webhook_transaction_update;
  //   return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  // },
};

export default service;
