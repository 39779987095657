import { createAsyncThunk } from '@reduxjs/toolkit';
import service from '../service';
import * as tp from '../types';
import { getMerchantInfo } from '../../transactions/store/thunk';
import customToast from '@/common/components/CustomToast';

export const createWallet = createAsyncThunk(
  'payment/createWallet',
  async (
    data: tp.IcreateWalletThunk,
    { rejectWithValue, fulfillWithValue },
  ) => {
    return service
      .createWallet(data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);

export const uploadKyc = createAsyncThunk(
  'payment/uploadKyc',
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .uploadKyc(data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);
export const generateKYCLink = createAsyncThunk(
  'payment/generateKYCLink',
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .createKycLink(id)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);

export const registerMerchant = createAsyncThunk(
  'payment/registerMerchant',
  async (
    data: tp.IcreateMerchantThunk,
    { rejectWithValue, fulfillWithValue },
  ) => {
    return service
      .registerMerchant(data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);

export const payoutMerchant = createAsyncThunk(
  'payment/payoutMerchant',
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .payoutMerchant(data)
      .then((res) => {
        customToast(res?.message);
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);

export const generateKey = createAsyncThunk(
  'payment/generateKey',
  async (
    data: tp.IgenerateSecretKeyThunk,
    { rejectWithValue, fulfillWithValue },
  ) => {
    return service
      .generateKey(data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);

export const toggleP2P = createAsyncThunk(
  'payment/toggleP2P',
  async (
    data: tp.ItoggleP2PThunk,
    { rejectWithValue, fulfillWithValue, dispatch },
  ) => {
    return service
      .toggleP2P(data)
      .then((res) => {
        dispatch(getMerchantInfo(data.user_id));
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);

export const getBankInfoFunding = createAsyncThunk(
  'payment/getBankInfoFunding',
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getBankInfoFunding(id)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);

export const getPartners = createAsyncThunk(
  'payment/getPartners',
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getPartners(id)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);

export const getAllBanks = createAsyncThunk(
  'payment/getAllBanks',
  async (data: tp.IallBanksThunk, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getAllBanks(data)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  },
);

export const resolveAccountByCurrency = createAsyncThunk(
  'payment/resolveAccountByCurrency',
  async (
    data: tp.IresolveAccountByCurrencyThunk,
    { rejectWithValue, fulfillWithValue },
  ) => {
    return service
      .resolveAccountByCurrency(data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  },
);
export const paymentWithdrawal = createAsyncThunk(
  'payment/paymentWithdrawal',
  async (
    data: tp.IwithDrawalPaymentThunk,
    { rejectWithValue, fulfillWithValue, dispatch },
  ) => {
    return service
      .paymentWithdrawal(data)
      .then((res) => {
        dispatch(getMerchantInfo(data.user_id));
        return fulfillWithValue(res);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);
export const walletHistory = createAsyncThunk(
  'payment/walletHistory',
  async (
    data: tp.WalletHistoryThunk,
    { rejectWithValue, fulfillWithValue },
  ) => {
    return service
      .walletHistory(data)
      .then((res) => {
        return fulfillWithValue(res);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);
export const getAddressBook = createAsyncThunk(
  'payment/getAddressBook',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getAddressBook()
      .then((res) => {
        return fulfillWithValue(res);
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  },
);
